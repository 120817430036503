.dflex {
   display: flex;
}

.jcc {
   justify-content: center;
}

.jcsb {
   justify-content: space-between;
}

.jcse {
   justify-content: space-evenly;
}

.jcsa {
   justify-content: space-around;
}

.aic {
   align-items: center;
}

.aifs{
   align-items: flex-start;
}

.fdn-row {
   flex-direction: row;
}

.fdn-col {
   flex-direction: column;
}
