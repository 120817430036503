:root {
   --priCol: #436bad;
   --white: #fff;
   --headingCol: #121212;
   --backGrey: #ececec;
   --underlineGrey: #adadad;
   --tempPriCol: var(--priCol);
   --tempWhite: var(--white);
   --borderCol: var(--backGrey);
   --textCol: var(--headingCol);
}

.owl-item {
   display: flex;
   justify-content: center;
}

.owl-dot.active > span {
   background-color: var(--priCol) !important;
}

.container {
   width: 300px;
   height: 400px;
   border-radius: 10px;
   border: 2px solid var(--borderCol);
   background-color: var(--tempWhite);
   box-shadow: 0 0 10px var(--backGrey);
   transform: scale(0.97);
   transition: 0.5s ease-in-out;
}

.container:hover {
   --tempWhite: var(--priCol);
   --tempPriCol: var(--white);
   --borderCol: var(--priCol);
   --textCol: var(--white);
   transform: scale(1);
   transition: 0.5s ease-in-out;
}

.invertedCommaContainer {
   position: absolute;
   top: 10px;
   left: 10px;
}

.invertedComma {
   color: var(--tempPriCol);
}

.upperContainer {
   height: 55%;
   width: 100%;
   border-top-right-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: flex-end;
   border-top-left-radius: 10px;
   position: relative;
}

.upperSubContainer {
   height: 80%;
   width: 50%;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
   padding-bottom: 10px;
   border-bottom: 1px solid var(--tempPriCol);
}

.imageContainer {
   margin-bottom: 10px;
   height: 100px;
   width: 100px;
   /* background-size: cover; */
}

.image {
   border-radius: 100%;
   height: inherit;
   width: inherit;
}

.namePostContainer {
   text-align: center;
   font-size: medium;
   font-weight: 600;
   color: var(--textCol);
}

.post {
   font-size: small;
   font-weight: 500;
}

.lowerContainer {
   height: 45%;
   width: 100%;
   color: var(--textCol);
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: small;
   text-align: justify;
   padding: 0 25px;
}
